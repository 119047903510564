const state = {
  language: '',
}

const mutations = {
  setLanguage(state, language) {
    state.language = language
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
