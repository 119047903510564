import Axios from 'axios'
import router from '../plugins/vueRouter'
import store from '../store/index'

const { VUE_APP_API_PROTOCOL, VUE_APP_API_HOST, VUE_APP_API_PORT } = process.env

class WebClient {
  static init() {
    return new WebClient()
  }

  constructor() {
    this.axios = Axios.create({ origin: 'http://localhost:8080' })
    this.baseUrl = this.getBaseUrl()
    this.routes = this.getRoutes()
  }

  getBaseUrl() {
    if (VUE_APP_API_PROTOCOL === 'http') {
      return `${VUE_APP_API_PROTOCOL}://${VUE_APP_API_HOST}:${VUE_APP_API_PORT}`
    }
    return `${VUE_APP_API_PROTOCOL}://${VUE_APP_API_HOST}`
  }

  getRoutes() {
    return {
      clients: '/clients',
      createBlacklistEntry: '/blacklist/entry',
      createUser: '/user',
      deleteBlacklistEntry: '/blacklist/entry',
      generateOrderDataExport: '/generate-order-export',
      getAddresses: '/addresses',
      getBlacklist: '/blacklist',
      getOrder: '/crm/order',
      getOrders: '/crm/orders',
      getUserOrders: '/orders',
      getUserById: '/user-info',
      getUserInfo: '/user-info',
      login: '/login',
      logout: '/logout',
      updateOrderPipeDriveId: '/update-order-pipedrive-id',
      updateOrderStatus: '/update-order-status',
      updateUserAlias: '/update-alias',
      updateUserExclusivity: '/update-exclusivity',
      updateUserInfo: '/user-info',
      updateUserPipeDriveId: '/update-pipedrive-id',
      updateUserWhitelist: '/update-whitelist',
    }
  }

  setBearerToken(token) {
    this.axios.defaults.headers.common = { Authorization: `bearer ${token}` }
  }

  getBearerToken() {
    return this.axios.defaults.headers.common?.Authorization || null
  }

  async post(args) {
    try {
      const { route, body, params } = args
      const res = await this.axios.post(`${this.baseUrl}${route}`, body, { params })
      return res.data
    } catch (error) {
      if (args.route.indexOf('/login') > -1) {
        throw new Error(error.response?.data.message || 'Something went wrong')
      }
      return this.handleAxiosError(error)
    }
  }

  async put(args) {
    try {
      const { route, body, params } = args
      const res = await this.axios.put(`${this.baseUrl}${route}/${params.id}`, body)
      return res.data
    } catch (error) {
      return this.handleAxiosError(error)
    }
  }

  async get(args) {
    try {
      const { route, params } = args
      const res = await this.axios.get(`${this.baseUrl}${route}`, { params })
      return res.data
    } catch (error) {
      return this.handleAxiosError(error)
    }
  }

  async delete(args) {
    try {
      const { route, params } = args
      const res = await this.axios.delete(`${this.baseUrl}${route}/${params.id}`)
      return res.data
    } catch (error) {
      return this.handleAxiosError(error)
    }
  }

  handleAxiosError(error) {
    const status = error.response?.status
    if (!status) {
      throw new Error(error)
    }
    if (status === 401 || status === 403) this.handleUnauthorized(status)
    throw new Error(error.response?.data.message || 'something went wrong')
  }

  handleUnauthorized(status) {
    if (status === 401) {
      store.commit('app/setUserLoggedIn', false)
      router.push({ path: '/login' })
      return
    }

    if (status === 403) {
      throw new Error('This user is not allowed to do this operation')
    }
  }
}

export default WebClient
