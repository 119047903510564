var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":50,"item-key":"reference"},scopedSlots:_vm._u([{key:"item.unitPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedPrice(item.unitPrice))+" ")]}},{key:"item.totalPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedPrice(item.totalPrice))+" ")]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedPrice(item.discount))+" ")]}},{key:"item.discountedTotalPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedPrice(item.discountedTotalPrice))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }