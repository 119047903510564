<template>
  <div class="crm-wrapper">
    <v-col sm="12">
      <v-btn outlined @click="showCreateUserForm = !showCreateUserForm">Create new user</v-btn>
    </v-col>
    <UsersTable v-if="!!users && !!users.length" :users="$store.state.users.users" />

    <v-dialog v-model="showCreateUserForm" scrollable max-width="600px">
      <CreateUserForm @close="showCreateUserForm = false" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UsersTable from '../components/UsersTable'
import CreateUserForm from '../components/CreateUserForm.vue'

export default {
  components: {
    UsersTable, //
    CreateUserForm,
  },

  data: () => ({
    showCreateUserForm: false,
  }),

  computed: mapState({ users: state => state.users.users }),

  async created() {
    try {
      await this.$store.dispatch('users/setUsers')
    } catch (error) {
      console.error(error)
    }
  },
}
</script>

<style>
.crm-wrapper {
  height: 100%;
}
</style>
