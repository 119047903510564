<template>
  <v-container fluid>
    <v-row v-if="loading" no-gutters>
      <v-overlay>
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-row>

    <v-row v-if="errored" no-gutters> Something went wrong, please refresh the page </v-row>

    <v-row v-else no-gutters>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-btn @click="showAreaSelector = true">Add a county or geographical zone</v-btn>
          </v-col>
        </v-row>

        <v-row v-for="(blacklist, area) in blacklistByCountryCode" :key="`bl${area}`" cols="12">
          <v-col cols="12" class="pb-0">
            <span>{{ $t(area) }}</span>
            <v-btn icon class="ml-4" color="green" @click="openBrandSelector(area)">
              <v-icon>mdi-plus</v-icon>
            </v-btn></v-col
          >
          <v-col cols="12">
            <v-chip
              v-for="entry in blacklist"
              :key="`bc${entry.id}${blacklist.area}`"
              class="ma-2"
              close
              label
              outlined
              @click:close="onDelete(entry.id)"
            >
              {{ $t(entry.brand) }}
            </v-chip>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="showAreaSelector" scrollable max-width="400px">
        <v-card>
          <v-card-title>Add a brand to {{ $t(selectedArea) }} blacklist</v-card-title>
          <v-divider></v-divider>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-card-text style="height: 300px;" class="mt-8">
            <v-autocomplete v-model="selectedArea" :items="chosableAreas" outlined />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="showAreaSelector = false">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="onAddArea()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="showBrandSelector" scrollable max-width="400px">
        <v-card>
          <v-card-title>Add a brand to {{ $t(selectedArea) }} blacklist</v-card-title>
          <v-divider></v-divider>
          <v-spacer></v-spacer>
          <v-card-text style="height: 300px;">
            <v-chip
              v-for="brand in chosableBrands"
              :key="`cb${brand}`"
              class="ma-2 cursor-pointer"
              label
              outlined
              @click="onAdd(selectedArea, brand)"
            >
              {{ $t(brand) }}
            </v-chip>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="showBrandSelector = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import CountryCodes from '../enums/CountryCodes'
import Brands from '../enums/Brands'
const brands = Object.keys(Brands)

export default {
  data: () => ({
    errored: false,
    loading: true,
    geoZones: [],
    blacklist: [],
    CountryCodes,
    showAreaSelector: false,
    showBrandSelector: false,
    selectedArea: null,
  }),

  computed: {
    blacklistByCountryCode() {
      return this.blacklist.reduce((acc, item) => {
        const area = item.countryCode || item.geoZoneName
        if (!acc[area]) acc[area] = []
        if (item.brand) acc[area].push(item)
        return acc
      }, {})
    },

    chosableAreas() {
      const geoZones = this.geoZones.map(({ name }) => name).filter((name, i, self) => self.indexOf(name) === i)
      const activeAreas = Object.keys(this.blacklistByCountryCode)
      return [...geoZones, ...CountryCodes]
        .filter(area => !activeAreas.includes(area))
        .map(area => ({
          text: this.$t(area),
          value: area,
        }))
    },

    chosableBrands() {
      if (!this.selectedArea) return []
      const selectedBlacklist = this.blacklistByCountryCode[this.selectedArea]
      return brands.filter(brandName => !selectedBlacklist.some(({ brand }) => brand === brandName))
    },
  },

  methods: {
    onAddArea() {
      if (this.selectedArea) {
        const isCountryCode = CountryCodes.includes(this.selectedArea)
        this.blacklist.unshift({
          countryCode: isCountryCode ? this.selectedArea : null,
          geoZoneName: !isCountryCode ? this.selectedArea : null,
        })
      }
      this.showAreaSelector = false
    },

    async refreshData() {
      try {
        this.loading = true
        const repository = this.$store.state.dependencies.backEndRepository
        const response = await repository.getBlacklist()
        this.blacklist = response.blacklist
        this.geoZones = response.geoZones
      } catch (error) {
        console.error(error)
        this.errored = true
      } finally {
        this.loading = false
      }
    },

    openBrandSelector(area) {
      this.selectedArea = area
      this.showBrandSelector = true
    },

    async onAdd(area, brand) {
      const isCountryCode = CountryCodes.includes(area)
      const payload = {
        brand,
        countryCode: isCountryCode ? area : null,
        geoZoneName: isCountryCode ? null : area,
      }
      const repository = this.$store.state.dependencies.backEndRepository
      await repository.createBlacklistEntry(payload)
      await this.refreshData()
    },

    async onDelete(blacklistEntryId) {
      try {
        const repository = this.$store.state.dependencies.backEndRepository
        await repository.deleteBlacklistEntry(blacklistEntryId)
        await this.refreshData()
      } catch (error) {
        console.error(error)
      }
    },
  },

  async created() {
    await this.refreshData()
  },
}
</script>
