<template>
  <v-row>
    <v-col cols="12" sm="6" xs="12" v-for="address in addresses" :key="address.uid">
      <v-card>
        <v-card-title>{{ address.addressType }} address</v-card-title>

        <v-card-text>
          <p>addressType: {{ address.addressType }}</p>
          <p>name: {{ address.name }}</p>
          <p>companyName: {{ address.companyName }}</p>
          <p>firstName: {{ address.firstName }}</p>
          <p>lastName: {{ address.lastName }}</p>
          <p>phone1: {{ getFormattedPhoneNumber(address.phone1CountryCode, address.phone1) }}</p>
          <p>phone2: {{ getFormattedPhoneNumber(address.phone2CountryCode, address.phone2) }}</p>
          <p>street1: {{ address.street1 }}</p>
          <p>street2: {{ address.street2 }}</p>
          <p>postCode: {{ address.postCode }}</p>
          <p>city: {{ address.city }}</p>
          <p>state: {{ address.state }}</p>
          <p>country: {{ address.country }}</p>
          <p>notes: {{ address.notes }}</p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { getFormattedPhoneNumber } from '../services/formatting'

export default {
  props: ['addresses'],

  data: () => ({
    getFormattedPhoneNumber,
  }),
}
</script>
