var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.theUsers,"items-per-page":50,"item-key":"uid"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",staticStyle:{"font-style":"italic"},attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.country))+" ")]}},{key:"item.exclusiveDistributorOf",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.exclusiveDistributorOf.length ? item.exclusiveDistributorOf.join(', ') : 'No brand')+" ")]}},{key:"item.pipedriveId",fn:function(ref){
var item = ref.item;
return [(item.pipedriveId)?_c('a',{attrs:{"target":"_blank","href":_vm.getPipeDrivePersonLink(item.pipedriveId)}},[_vm._v(_vm._s(item.pipedriveId))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/user/" + (item.uid))}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }