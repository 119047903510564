import TickerToSymbol from '../enums/TickerToSymbol'

export const getFormattedPrice = price => {
  if (!price) return 0

  return Number(Number(price) / 100).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const getFormattedPriceWithCurrency = (priceInCents, currency) => {
  if (!priceInCents) return 0

  const price = Number(Number(priceInCents) / 100)
  const prettyPrice = price.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  return `${TickerToSymbol[currency]} ${prettyPrice}`
}

export const getFormattedPhoneNumber = (countryCode, phoneNumber) => {
  const suffix = countryCode ? `(${countryCode})` : ''
  return `${suffix}${phoneNumber}`
}
