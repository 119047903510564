import WebClient from '../services/WebClient'

class BackEndRepository {
  static init() {
    return new BackEndRepository()
  }

  constructor(webClient) {
    this.client = webClient || WebClient.init()
  }

  async getClients() {
    return this.client.get({ route: this.client.routes.clients })
  }

  async updateUserExclusivity(email, exclusiveDistributorOf) {
    return this.client.post({
      route: this.client.routes.updateUserExclusivity, //
      body: { email, exclusiveDistributorOf },
    })
  }

  async updateUserWhitelist(email, whitelist) {
    return this.client.post({
      route: this.client.routes.updateUserWhitelist, //
      body: { email, whitelist },
    })
  }

  async updateUserPipeDriveId(email, pipedriveId) {
    return this.client.post({
      route: this.client.routes.updateUserPipeDriveId, //
      body: { email, pipedriveId },
    })
  }

  async updateUserInfo(userInformation) {
    return this.client.put({
      route: this.client.routes.updateUserInfo,
      body: { userInformation },
      params: { id: userInformation.uid },
    })
  }

  async updateOrderPipeDriveId(uid, pipedriveId) {
    return this.client.post({
      route: this.client.routes.updateOrderPipeDriveId, //
      body: { uid, pipedriveId },
    })
  }

  async updateOrderStatus(uid, status) {
    return this.client.post({
      route: this.client.routes.updateOrderStatus, //
      body: { uid, status },
    })
  }

  async getUserInfo() {
    return this.client.get({ route: this.client.routes.getUserInfo })
  }

  async getUserById(id) {
    return this.client.get({ route: `${this.client.routes.getUserById}/${id}` })
  }

  async getUserAddresses() {
    return this.client.get({ route: this.client.routes.getAddresses })
  }

  async getOrder(id) {
    return this.client.get({ route: this.client.routes.getOrder, params: { id } })
  }

  async getOrders() {
    return this.client.get({ route: this.client.routes.getOrders })
  }

  async generateOrderDataExport(uid) {
    return this.client.post({
      route: this.client.routes.generateOrderDataExport, //
      body: { uid },
    })
  }

  async createUser(payload) {
    return this.client.post({ route: this.client.routes.createUser, body: payload })
  }

  async login(email, password) {
    const token = await this.client.post({ route: this.client.routes.login, body: { email, password } })
    this.client.setBearerToken(token)
    return token
  }

  async logout() {
    return this.client.get({ route: this.client.routes.logout })
  }

  async getBlacklist() {
    return this.client.get({ route: this.client.routes.getBlacklist })
  }

  async createBlacklistEntry(body) {
    return this.client.post({ route: this.client.routes.createBlacklistEntry, body })
  }

  async deleteBlacklistEntry(id) {
    return this.client.delete({ route: this.client.routes.deleteBlacklistEntry, params: { id } })
  }

  async updateUserAlias(uid, alias) {
    return this.client.post({ route: this.client.routes.updateUserAlias, body: { uid, alias } })
  }

  async getUserOrders(uid) {
    return this.client.get({ route: `${this.client.routes.getUserOrders}/${uid}` })
  }
}

export default BackEndRepository
