<template>
  <v-card class="mx-auto">
    <v-card-title>Create a new user</v-card-title>

    <v-card-text>
      <div>The user email will be considered as already confirmed by the user.</div>
      <div>The email can be a fake one, but it should appear as a valid email (eg: email@example.com).</div>
    </v-card-text>
    <v-divider />

    <v-form @submit="onCreateUser" onsubmit="event.preventDefault()" ref="form">
      <v-card-text>
        <v-text-field v-model="user.email" label="E-mail" :rules="emailRules" outlined required></v-text-field>
        <v-text-field v-model="user.password" label="Password" :rules="passwordRules" outlined required></v-text-field>
        <v-autocomplete v-model="user.country" label="Country" :items="countries" outlined required />
      </v-card-text>

      <v-divider />

      <v-card-text v-if="errorMessage">
        <div>{{ errorMessage }}</div>
      </v-card-text>

      <v-card-actions>
        <v-btn color="blue darken-1" text @click="$emit('close')"> Close </v-btn>
        <v-btn color="blue darken-1" text @click="onCreateUser" :loading="loading"> Create a new user </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import ReadablePassword from 'readable-password'
import CountryCodes from '../enums/CountryCodes'

export default {
  data: () => ({
    emailRules: [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
    passwordRules: [
      v => !!v || 'Password is required',
      v => (v && v.length > 6) || 'Password length should be greater than 6 characters',
    ],
    user: {
      email: '',
      password: ReadablePassword.randomString(8, 2),
      country: '',
    },
    loading: false,
    errorMessage: '',
  }),

  computed: {
    countries() {
      return CountryCodes.map(country => ({ text: this.$t(country), value: country }))
    },
  },

  methods: {
    async onCreateUser() {
      try {
        this.loading = true
        this.errorMessage = ''
        await this.$refs.form.validate()
        await this.$store.dispatch('users/createNewUser', this.user)
        this.$emit('close')
      } catch (error) {
        console.error(error)
        this.errorMessage =
          'Something went wrong, please try again. Note that the user email must not already exist in our user database.'
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
