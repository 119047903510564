import VueRouter from 'vue-router'

import store from '../store/index'

import BrandsBlacklist from '../views/BrandsBlacklist'
import Geozones from '../views/Geozones'
import Login from '../views/Login'
import OrderDetail from '../components/OrderDetail'
import Orders from '../views/Orders'
import UserDetail from '../components/UserDetail'
import Users from '../views/Users'

const routes = [
  { path: '/login', name: 'LOGIN', component: Login },
  { path: '/order/:id', name: 'ORDER DETAIL', component: OrderDetail, meta: { protected: true } },
  { path: '/orders', name: 'ORDERS', component: Orders, meta: { protected: true } },
  { path: '/user/:id', name: 'USER DETAIL', component: UserDetail, meta: { protected: true } },
  { path: '/users', name: 'USERS', component: Users, meta: { protected: true } },
  { path: '/brands-blacklist', name: 'BRAND BLACKLIST', component: BrandsBlacklist, meta: { protected: true } },
  { path: '/geozones', name: 'GEOZONES', component: Geozones, meta: { protected: true } },
  { path: '/', redirect: '/orders', component: Orders, meta: { protected: true } },
  { path: '/*', redirect: '/orders', component: Orders, meta: { protected: true } },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (!to.meta.protected || store.state.app.isUserLoggedIn) {
    return next()
  }

  if (!store.state.app.isUserLoggedIn) {
    const nowLoggedIn = await store.dispatch('app/setUserInfo')
    if (nowLoggedIn) return next()
  }

  router.push({ path: '/login' })
})

export default router
