<template>
  <v-container fluid>
    <v-row v-if="loading" no-gutters>
      <v-overlay>
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-row>

    <v-row v-if="errored" no-gutters> Something went wrong, please refresh the page </v-row>

    <v-row v-else no-gutters>
      <v-container fluid>
        <v-row v-for="(areas, geoZoneName) in geoZonesView" :key="`bl${geoZoneName}`" cols="12">
          <v-col cols="12" class="pb-0">
            <span>{{ $t(geoZoneName) }}</span>
          </v-col>
          <v-col cols="12">
            <v-chip v-for="area in areas" :key="`bc${area.countryCode}`" class="ma-2" label outlined>
              {{ $t(area.countryCode) }}
            </v-chip>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    errored: false,
    loading: true,
    geoZones: [],
  }),

  computed: {
    geoZonesView() {
      return this.geoZones.reduce((acc, zone) => {
        if (!acc[zone.name]) acc[zone.name] = []
        acc[zone.name].push(zone)
        return acc
      }, {})
    },
  },

  methods: {
    async refreshData() {
      try {
        this.loading = true
        const repository = this.$store.state.dependencies.backEndRepository
        const response = await repository.getBlacklist()
        this.geoZones = response.geoZones
      } catch (error) {
        console.error(error)
        this.errored = true
      } finally {
        this.loading = false
      }
    },
  },

  async created() {
    await this.refreshData()
  },
}
</script>
