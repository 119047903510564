<template>
  <v-app class="app">
    <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" permanent app>
      <v-list-item class="px-2">
        <v-btn icon @click.stop="mini = !mini">
          <v-icon v-if="mini">mdi-chevron-right</v-icon>
          <v-icon v-if="!mini">mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider />

      <v-list dense>
        <router-link tag="a" :to="item.path" v-for="item in items" :key="item.title">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app> {{ $route.name }} </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <v-footer app>
      <a @click="logout()">Logout</a>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    drawer: true,
    items: [
      { title: 'Users', icon: 'mdi-account-group-outline', path: '/users', name: 'USERS' },
      { title: 'Orders', icon: 'mdi-currency-usd', path: '/orders', name: 'ORDERS' },
      { title: 'Brands blacklist', icon: 'mdi-key-arrow-right', path: '/brands-blacklist', name: 'BRANDS BLACKLIST' },
      { title: 'Geozones', icon: 'mdi-earth', path: '/geozones', name: 'GEOZONES' },
    ],
    mini: true,
  }),

  methods: {
    async logout() {
      try {
        await this.$store.dispatch('app/logout')
        this.$router.push({ path: '/login' })
      } catch (error) {
        console.error(error)
      }
    },
  },

  created() {
    const { backEndRepository } = this.$store.state.dependencies
    if (!backEndRepository.client.getBearerToken()) this.$store.dispatch('app/setBearerToken')
  },
}
</script>

<style lang="scss">
a {
  text-decoration: none;
}
</style>
