import Vue from 'vue'
import VueRouter from 'vue-router'

import i18n from './plugins/language'
import router from './plugins/vueRouter'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

Vue.use(VueRouter)

const vueInstance = new Vue({
  render: h => h(App),
  router,
  store,
  vuetify,
  i18n,
}).$mount('#app')

const navLang = navigator.language
const localLang = navLang === 'zh-CN' || navLang === 'en-US' ? navLang : null
vueInstance.$i18n.locale = vueInstance.$store.state.language.language || localLang || 'en-US'
vueInstance.$store.commit('language/setLanguage', vueInstance.$i18n.locale)
