const state = {
  users: [],
}

const mutations = {
  setUsers(state, users) {
    state.users = users
  },
}

const getters = {
  getUserByUid: state => uid => {
    return state.users.find(user => user.uid === uid)
  },
}

const actions = {
  async setUsers({ commit, rootState }) {
    const users = await rootState.dependencies.backEndRepository.getClients()
    commit('setUsers', users)
  },

  async updateUserExclusivity({ dispatch, rootState }, { email, exclusiveDistributorOf }) {
    const { backEndRepository } = rootState.dependencies
    await backEndRepository.updateUserExclusivity(email, exclusiveDistributorOf)
    await dispatch('setUsers')
  },

  async updateUserWhitelist({ dispatch, rootState }, { email, whitelist }) {
    const { backEndRepository } = rootState.dependencies
    await backEndRepository.updateUserWhitelist(email, whitelist)
    await dispatch('setUsers')
  },

  async updateUserPipeDriveId({ dispatch, rootState }, { email, pipedriveId }) {
    const { backEndRepository } = rootState.dependencies
    await backEndRepository.updateUserPipeDriveId(email, pipedriveId)
    await dispatch('setUsers')
  },

  async createNewUser({ dispatch, rootState }, payload) {
    const { backEndRepository } = rootState.dependencies
    await backEndRepository.createUser(payload)
    await dispatch('setUsers')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
