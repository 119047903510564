import Vue from 'vue'
import VueX from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import app from './app'
import dependencies from './dependencies'
import language from './language'
import orders from './orders'
import users from './users'

const debug = process.env.NODE_ENV !== 'production'

Vue.use(VueX)

const persistedState = createPersistedState({
  paths: [
    'app', //
    'language',
    'orders',
    'users',
  ],
})

export default new VueX.Store({
  modules: {
    app, //
    dependencies,
    language,
    orders,
    users,
  },
  strict: debug,
  plugins: [persistedState],
})
