<template>
  <v-row no-gutters>
    <v-spacer />
    <v-col cols="12" sm="4">
      <v-card class="pa-2" outlined tile>
        <v-form @submit="validate" onsubmit="event.preventDefault()">
          <v-text-field v-model="email" label="E-mail" required></v-text-field>
          <v-text-field v-model="password" type="password" label="Password" required></v-text-field>
          <v-btn color="success" class="mr-4" type="submit"> Login </v-btn>
        </v-form>
        <div v-if="error">{{ error }}</div>
      </v-card>
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
export default {
  data: () => ({
    email: '',
    password: '',
    error: '',
  }),

  methods: {
    async validate() {
      try {
        const { email, password } = this
        await this.$store.dispatch('app/passwordLogin', { email, password })
        await this.$store.dispatch('app/setUserInfo')
        this.$router.push({ path: '/' })
        this.error = ''
      } catch (error) {
        this.error = error
      }
    },
  },
}
</script>
