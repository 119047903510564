<template>
  <v-col cols="12" sm="12" xs="12">
    <v-card>
      <v-card-title>General information</v-card-title>

      <v-card-text>
        <p v-if="user">Client: {{ getClientName(user) }}</p>
        <p>ID: {{ header.uid }}</p>
        <p>Date: {{ getFormattedDate(header.totalInBaseCurrency) }}</p>
        <p>
          Status: {{ $t(header.status) }}
          <v-btn text color="success" @click="showUpdateStatus = !showUpdateStatus" class="ml-4">
            <v-icon left> mdi-pencil </v-icon>
            Edit
          </v-btn>
        </p>
        <p>Total: {{ getFormattedPrice(header.totalInBaseCurrency) }} {{ user.currency }}</p>
        <p>Discount: {{ getFormattedPrice(header.discountInBaseCurrency) }} {{ user.currency }}</p>
        <p>Discounted total: {{ getFormattedPrice(header.discountedTotalInBaseCurrency) }} {{ user.currency }}</p>
        <p>Notes: {{ header.notes }}</p>
        <p v-if="header.packingType">Packing type: {{ header.packingType }}</p>
        <p v-if="header.shipmentMode">Shipment mode: {{ header.shipmentMode }}</p>
        <p>
          <a
            v-if="header.pipedriveId"
            :href="getPipeDriveDealLink(header.pipedriveId)"
            target="_blank"
            rel="noopener noreferrer"
            >PipeDrive ID: {{ header.pipedriveId }}</a
          >
          <span v-else>PipeDrive ID: {{ header.pipedriveId || 'Not set' }}</span>

          <v-btn text color="success" @click="showUpdatePipeDriveId = !showUpdatePipeDriveId" class="ml-4">
            <v-icon left> mdi-pencil </v-icon>
            Edit
          </v-btn>
        </p>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showUpdateStatus" scrollable max-width="600px">
      <v-card class="mx-auto">
        <v-card-title>Update order status</v-card-title>

        <v-card-text>
          <v-select :items="orderStatuses" v-model="chosenStatus" />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="showUpdateStatus = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showUpdatePipeDriveId" scrollable max-width="600px">
      <v-card class="mx-auto">
        <v-card-title>Update order PipeDrive ID</v-card-title>
        <v-card-text>
          <p>The ID is the number that appears in the URL, as per the picture below</p>
          <p>Note that this should be the ID of a Deal, not the ID of an organization</p>

          <img :src="pipedriveIdLocation" />
        </v-card-text>

        <v-divider />
        <v-card-text>
          <v-text-field
            ref="chosenPipeDriveId"
            v-model="chosenPipeDriveId"
            :rules="[() => !!chosenPipeDriveId || 'This field is required']"
            label="PipeDrive ID"
            placeholder="1234"
            required
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="showUpdatePipeDriveId = false"> Close </v-btn>
          <v-btn color="blue darken-1" text @click="onUpdatePipeDriveId"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import moment from 'moment'

import pipedriveIdLocation from '../../public/images/pipedrive-deal-id-location.png'

import OrderStatuses from '../enums/OrderStatuses'

import { getFormattedPrice } from '../services/formatting'
import { getPipeDriveDealLink } from '../services/ordersService'
import { mapGetters } from 'vuex'

export default {
  props: ['header'],

  data: () => ({
    showUpdateStatus: false,
    showUpdatePipeDriveId: false,
    chosenPipeDriveId: '',
    getFormattedPrice,
    getPipeDriveDealLink,
    orderStatuses: Object.keys(OrderStatuses),
    pipedriveIdLocation,
    clientName: '',
    user: null,
  }),

  computed: {
    chosenStatus: {
      get: function() {
        return this.header.status
      },

      set: async function(status) {
        const { uid } = this.header
        await this.$store.dispatch('orders/updateOrderStatus', { uid, status })
        this.showUpdateStatus = false
      },
    },

    ...mapGetters({
      getUserByUid: 'users/getUserByUid',
    }),
  },

  methods: {
    // Duplicate code
    getClientName({ firstName, lastName, alias }) {
      let nameView = ''
      if (firstName) nameView += firstName
      if (lastName) nameView += ` ${lastName}`
      if (alias) nameView += ` (${alias})`
      return nameView
    },

    getFormattedDate({ createdAt }) {
      return moment(createdAt).format('L')
    },

    async onUpdatePipeDriveId() {
      try {
        const { uid } = this.header
        const pipedriveId = this.chosenPipeDriveId
        await this.$store.dispatch('orders/updateOrderPipeDriveId', { uid, pipedriveId })
        this.showUpdatePipeDriveId = false
      } catch (error) {
        console.error(error)
      }
    },
  },

  async created() {
    let user = this.getUserByUid(this.header.clientUid)

    if (!user) {
      await this.$store.dispatch('users/setUsers', this.header.clientUid)
      user = this.getUserByUid(this.header.clientUid)
    }

    if (!user) return 'Unknown'

    this.user = user
  },
}
</script>
