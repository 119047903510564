const state = {
  user: {},
  bearerToken: '',
  isUserLoggedIn: undefined,
}

const mutations = {
  setUserInfo(state, userInfo) {
    state.user = userInfo
  },
  setUserLoggedIn(state, bool) {
    state.isUserLoggedIn = bool
  },
  setBearerToken(state, token) {
    state.bearerToken = token
  },
}

const actions = {
  async initialize({ dispatch }) {
    dispatch('setBearerToken')
    await dispatch('setUserInfo')
  },

  async setUserInfo({ commit, rootState, dispatch }) {
    try {
      dispatch('setBearerToken')
      const userInfo = await rootState.dependencies.backEndRepository.getUserInfo()
      commit('setUserInfo', userInfo)
      commit('setUserLoggedIn', true)
      return true
    } catch (error) {
      console.error(error)
      commit('setUserLoggedIn', false)
      return false
    }
  },

  async passwordLogin({ rootState, commit }, { email, password }) {
    const repository = rootState.dependencies.backEndRepository
    const token = await repository.login(email, password)
    commit('setBearerToken', token)
    commit('setUserLoggedIn', true)
  },

  async logout({ rootState, commit }) {
    const repository = rootState.dependencies.backEndRepository
    await repository.logout()
    commit('setBearerToken', '')
    commit('setUserLoggedIn', false)
  },

  setBearerToken({ state, rootState }) {
    if (state.bearerToken) {
      rootState.dependencies.backEndRepository.client.setBearerToken(state.bearerToken)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
