<template>
  <v-data-table :headers="headers" :items="displayOrders" :items-per-page="50" class="elevation-1" item-key="uid">
    <template v-slot:top>
      <v-text-field v-model="search" label="Search" class="mx-4" style="font-style: italic" />
    </template>

    <template v-slot:[`item.status`]="{ item }"> {{ $t(item.status) }} </template>

    <template v-slot:[`item.pipedriveId`]="{ item }">
      <a v-if="item.pipedriveId" target="_blank" :href="getPipeDriveDealLink(item.pipedriveId)">{{
        item.pipedriveId
      }}</a>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <router-link :to="`/order/${item.uid}`">
        <v-icon small class="mr-2"> mdi-eye </v-icon>
      </router-link>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

import { getFormattedPriceWithCurrency } from '../services/formatting'
import { getPipeDriveDealLink } from '../services/ordersService'

export default {
  props: ['orders'],

  data: () => ({
    headers: [
      { text: 'DATE', value: 'date' },
      { text: 'STATUS', value: 'status' },
      { text: 'CLIENT', value: 'client' },
      { text: 'TOTAL', value: 'total' },
      { text: 'PIPEDRIVE ID', value: 'pipedriveId' },
      { text: 'ACTIONS', value: 'actions' },
    ],

    search: '',

    getPipeDriveDealLink,
  }),

  computed: {
    ...mapGetters({
      getUserByUid: 'users/getUserByUid',
    }),

    displayOrders() {
      return this.orders
        .map(order => {
          const user = this.getUserByUid(order.clientUid)

          return {
            uid: order.uid,
            date: this.getFormattedDate(order),
            status: order.status,
            client: `${this.getClientName(user)}`,
            total: getFormattedPriceWithCurrency(order.totalInBaseCurrency, user.currency),
            user,
          }
        })
        .filter(({ user: { email, alias, firstName, lastName, country } }) => {
          if (!this.search) return true

          return (
            email?.toLowerCase().includes(this.search.toLowerCase()) ||
            alias?.toLowerCase().includes(this.search.toLowerCase()) ||
            firstName?.toLowerCase().includes(this.search.toLowerCase()) ||
            lastName?.toLowerCase().includes(this.search.toLowerCase()) ||
            country?.toLowerCase().includes(this.search.toLowerCase())
          )
        })
    },
  },

  methods: {
    // Duplicate code
    getClientName({ firstName, lastName, alias }) {
      let nameView = ''
      if (firstName) nameView += firstName
      if (lastName) nameView += ` ${lastName}`
      if (alias) nameView += ` (${alias})`
      return nameView
    },

    getFormattedDate({ createdAt }) {
      return moment(createdAt).format('L')
    },
  },
}
</script>
