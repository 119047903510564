<template>
  <v-container>
    <v-col v-if="user" cols="20" sm="20" xs="20">
      <v-card class="mx-auto" max-width="auto">
        <v-card-title class="mb-0 pb-0 section-title">
          {{ getUserDisplayName() }}
          <div>
            <a
              v-if="user.pipedriveId"
              :href="getPipeDrivePersonLink(user.pipedriveId)"
              target="_blank"
              rel="noopener noreferrer"
            >
            </a>

            <div v-if="editModeOn">
              <a class="body-1 ml-2" @click="showUpdatePipeDriveId = !showUpdatePipeDriveId">
                update Pipedrive id
              </a>
              <span class="body-1"> | </span>
              <a class="body-1" @click="showUpdateAlias = !showUpdateAlias"> update user alias </a>
            </div>
          </div>

          <v-spacer />

          <div>
            <v-btn @click="editModeOn = !editModeOn" color="black" icon>
              <v-icon v-if="editModeOn">mdi-pencil-off</v-icon>
              <v-icon v-else>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="my-0">{{ user.email }}</div>
          <div class="my-0"><v-icon small color="black"> mdi-earth </v-icon> {{ $t(user.country) }}</div>
        </v-card-text>

        <v-divider />

        <v-card-title class="card-title1 mb-0 pb-0">Exclusive distributor of</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="20" sm="20" md="20" class="column_wrapper1">
                <v-checkbox
                  v-for="brand in brands"
                  :key="`k${brand}`"
                  :input-value="chosenBrandsForExclusivity.includes(brand)"
                  :label="$t(brand)"
                  color="deep-grey accent-4"
                  @click="onExclusivitylistCheckboxClicked(brand)"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-title class="card-title1 mb-0 pb-0">Update user brand whitelist</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="20" sm="20" md="20" class="column_wrapper2">
                <v-checkbox
                  v-for="(brand, i) in brands"
                  :key="`k${i}`"
                  :input-value="userWhitelist.includes(brand)"
                  :label="$t(brand)"
                  color="deep-grey accent-4"
                  @click="onWhitelistCheckboxClicked(brand)"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-spacer />
    </v-col>

    <v-col v-else cols="12" sm="6" xs="12">
      <Loading />
    </v-col>

    <v-col v-if="loadingOrders" cols="12">
      <Loading />
    </v-col>

    <v-col v-else-if="orders.length" cols="12">
      <OrdersTable :orders="orders" />
    </v-col>

    <v-col v-else cols="12" style="padding:170px;margin-top:-100px">
      This client didn't order anything yet.
    </v-col>

    <v-dialog v-model="showUpdateAlias" scrollable max-width="300px">
      <v-card class="mx-auto" max-width="500">
        <v-card-title>Update user alias</v-card-title>
        <v-card-text>
          <p>The alias is only used internally by Kastline</p>
        </v-card-text>

        <v-divider />
        <v-card-text>
          <v-text-field
            ref="chosenAlias"
            v-model="chosenAlias"
            :rules="[() => !!chosenAlias || 'This field is required']"
            label="Alias"
            placeholder="SpaceX"
            required
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="showUpdateAlias = false"> Close </v-btn>
          <v-btn color="blue darken-1" text @click="onUpdateAlias"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showUpdatePipeDriveId" scrollable max-width="600px">
      <v-card class="mx-auto">
        <v-card-title>Update user PipeDrive ID</v-card-title>
        <v-card-text>
          <p>The ID is the number that appears in the URL, as per the picture below</p>
          <p>Note that this should be the ID of a Person, not the ID of an organization</p>

          <img :src="pipedriveIdLocation" />
        </v-card-text>

        <v-divider />
        <v-card-text>
          <v-text-field
            ref="chosenPipeDriveId"
            v-model="chosenPipeDriveId"
            :rules="[() => !!chosenPipeDriveId || 'This field is required']"
            label="PipeDrive ID"
            placeholder="1234"
            required
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="showUpdatePipeDriveId = false"> Close </v-btn>
          <v-btn color="blue darken-1" text @click="onUpdatePipeDriveId"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getPipeDrivePersonLink } from '../services/usersService'
import Brands from '../enums/Brands'
import pipedriveIdLocation from '../../public/images/pipedrive-id-location.png'
import pipedriveIcon from '../../public/images/icons/pipedrive.svg'
import OrdersTable from '../components/OrdersTable'
import Loading from '../components/Loading'

export default {
  components: {
    OrdersTable,
    Loading,
  },

  data: () => ({
    editModeOn: false,
    loadingOrders: false,
    orders: [],
    user: null,
    brands: Object.keys(Brands),
    showUpdateAlias: false,
    showUpdateExclusivity: false,
    showUpdateWhitelist: false,
    showUpdatePipeDriveId: false,
    chosenAlias: '',
    userWhitelist: [],
    chosenBrandsForExclusivity: [],
    chosenPipeDriveId: '',
    getPipeDrivePersonLink,
    pipedriveIdLocation,
    pipedriveIcon,
  }),

  computed: {
    userId() {
      return this.$route.params.id
    },
  },

  methods: {
    async onExclusivitylistCheckboxClicked(brand) {
      try {
        const brandIsAlreadyInExclusivitylist = this.chosenBrandsForExclusivity.includes(brand)

        if (brandIsAlreadyInExclusivitylist) {
          const newBrandList = this.chosenBrandsForExclusivity.filter(b => b !== brand)
          this.chosenBrandsForExclusivity = newBrandList
        } else {
          this.chosenBrandsForExclusivity.push(brand)
        }
        await this.onUpdateExclusivity()
      } catch (error) {
        console.error(error)
      }
    },

    async onWhitelistCheckboxClicked(brand) {
      try {
        const brandIsAlreadyInWhitelist = this.userWhitelist.includes(brand)

        if (brandIsAlreadyInWhitelist) {
          const newList = this.userWhitelist.filter(b => b !== brand)
          this.userWhitelist = newList
        } else {
          this.userWhitelist.push(brand)
        }

        await this.onUpdateWhitelist()
      } catch (error) {
        console.error(error)
      }
    },

    getUserDisplayName() {
      let displayName = ''
      if (this.user.firstName) displayName += this.user.firstName + ' '
      if (this.user.lastName) displayName += this.user.lastName + ' '
      if (this.user.alias) displayName += `(${this.user.alias})`

      if (!displayName) displayName = 'No user name or alias set'
      return displayName
    },

    async refreshUser() {
      try {
        const { backEndRepository } = this.$store.state.dependencies

        this.user = await backEndRepository.getUserById(this.userId)
        this.userWhitelist = [...this.user.brandWhitelist]
        this.chosenBrandsForExclusivity = this.user.exclusiveDistributorOf
      } catch (error) {
        console.error(error)
      }
    },

    async refreshOrders() {
      try {
        this.loadingOrders = true
        const { backEndRepository } = this.$store.state.dependencies
        this.orders = await backEndRepository.getUserOrders(this.userId)
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingOrders = false
      }
    },

    async onUpdateAlias() {
      try {
        const { backEndRepository } = this.$store.state.dependencies
        await backEndRepository.updateUserAlias(this.userId, this.chosenAlias)
        await this.refreshUser()
        this.showUpdateAlias = false
      } catch (error) {
        console.error(error)
      }
    },

    async onUpdateExclusivity() {
      try {
        const { email } = this.user
        const exclusiveDistributorOf = this.chosenBrandsForExclusivity
        const brandsList = JSON.stringify(exclusiveDistributorOf)
        await this.$store.dispatch('users/updateUserExclusivity', { email, exclusiveDistributorOf: brandsList })
        this.showUpdateExclusivity = false
        await this.refreshUser()
      } catch (error) {
        console.error(error)
      }
    },

    async onUpdateWhitelist() {
      try {
        const { email } = this.user
        const whitelist = JSON.stringify(this.userWhitelist)
        await this.$store.dispatch('users/updateUserWhitelist', { email, whitelist })
        this.showUpdateWhitelist = false
        await this.refreshUser()
      } catch (error) {
        console.error(error)
      }
    },

    async onUpdatePipeDriveId() {
      try {
        const { email } = this.user
        const pipedriveId = this.chosenPipeDriveId
        await this.$store.dispatch('users/updateUserPipeDriveId', { email, pipedriveId })
        this.showUpdatePipeDriveId = false
        await this.refreshUser()
      } catch (error) {
        console.error(error)
      }
    },
  },

  async created() {
    await this.refreshUser()
    await this.refreshOrders()
  },
}
</script>
<style lang="css">
.mx-auto {
  border: dashed black;
}
.section-title {
  font-weight: bolder;
  color: #000000;
}
.p1 {
  font-size: 12px;
  color: #37474f;
  margin-top: -30px;
}
.pipedrive-icon {
  width: 30px;
  margin-left: 12px;
  display: list-item;
}

.column_wrapper1 {
  column-count: 3;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 10px;
}
.column_wrapper2 {
  column-count: 3;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 10px;
}
.card-title1 {
  font-size: 14px;
}
</style>
