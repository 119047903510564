<template>
  <v-container>
    <v-card>
      <v-tabs v-model="tab" background-color="deep-purple accent-4" center-active dark>
        <v-tab>Order Information</v-tab>
        <v-tab>Order Items</v-tab>
        <v-tab>Order Addresses</v-tab>
        <v-tab>Data Export</v-tab>
      </v-tabs>
    </v-card>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-row>
          <OrderHeader :header="orderHeader" />
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <v-row>
          <OrderItems v-if="fullOrder" :items="fullOrder.items" />
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <OrderAddresses v-if="fullOrder" :addresses="fullOrder.addresses" />
      </v-tab-item>

      <v-tab-item>
        <v-container>
          <div class="export-button-container">
            <v-btn :loading="isGeneratingOrder" @click="generateOrderDataExport"
              >Generate a new export file for this order</v-btn
            >
          </div>
          <div v-if="orderExportFileUrl">
            <a :href="orderExportFileUrl" target="_blank" rel="noopener noreferrer">Open the data export file</a>
          </div>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
const { VUE_APP_CDN_URL } = process.env

import { mapGetters } from 'vuex'

import OrderAddresses from './OrderAddresses'
import OrderHeader from './OrderHeader'
import OrderItems from './OrderItems'

export default {
  components: {
    OrderAddresses, //
    OrderHeader,
    OrderItems,
  },

  data: () => ({
    tab: null, //
    fullOrder: null,
    isGeneratingOrder: false,
  }),

  computed: {
    ...mapGetters({
      getOrderById: 'orders/getOrderById',
    }),

    orderHeader() {
      return this.getOrderById(this.orderUid)
    },

    orderUid() {
      return this.$route.params.id
    },

    orderExportFileUrl() {
      const fileName = this.orderHeader.exportFileName
      if (!fileName) return ''
      return `${VUE_APP_CDN_URL}/files/${fileName}.xlsx`
    },
  },

  methods: {
    async generateOrderDataExport() {
      try {
        this.isGeneratingOrder = true
        await this.$store.dispatch('orders/generateOrderDataExport', this.orderHeader.uid)
      } catch (error) {
        console.error(error)
      } finally {
        this.isGeneratingOrder = false
      }
    },
  },

  async created() {
    try {
      this.fullOrder = await this.$store.state.dependencies.backEndRepository.getOrder(this.orderUid)
    } catch (error) {
      console.error(error)
    }
  },
}
</script>

<style lang="scss">
.export-button-container {
  margin: 20px 0;
}
</style>
