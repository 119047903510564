import BackendRepository from '../repositories/BackEndRepository'

const state = {
  backEndRepository: BackendRepository.init(),
}

export default {
  namespaced: true,
  state,
}
