<template>
  <v-container>
    <v-row v-if="!errored" no-gutters>
      <v-overlay v-if="!orders.length" :value="!orders.length">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>

      <v-col cols="12">
        <v-spacer />
        <OrdersTable v-if="orders.length" OrdersTable :orders="orders" />
        <v-spacer />
      </v-col>
    </v-row>

    <v-row v-if="errored"> Something went wrong, please refresh the page </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import OrdersTable from '../components/OrdersTable'

export default {
  components: { OrdersTable },

  data: () => ({
    errored: false,
  }),

  computed: mapState({ orders: state => state.orders.orders }),

  async created() {
    try {
      await this.$store.dispatch('orders/setOrders')
    } catch (error) {
      this.errored = true
    }
  },
}
</script>
