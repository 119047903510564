const state = {
  orders: [],
}

const mutations = {
  setOrders(state, orders) {
    state.orders = orders
  },
}

const getters = {
  getOrderById: state => id => {
    return state.orders.find(({ uid }) => uid === id)
  },
}

const actions = {
  async setOrders({ commit, rootState }) {
    const orders = await rootState.dependencies.backEndRepository.getOrders()
    commit('setOrders', orders)
  },

  async updateOrderPipeDriveId({ dispatch, rootState }, { uid, pipedriveId }) {
    const { backEndRepository } = rootState.dependencies
    await backEndRepository.updateOrderPipeDriveId(uid, pipedriveId)
    await dispatch('setOrders')
  },

  async updateOrderStatus({ dispatch, rootState }, { uid, status }) {
    const { backEndRepository } = rootState.dependencies
    await backEndRepository.updateOrderStatus(uid, status)
    await dispatch('setOrders')
  },

  async generateOrderDataExport({ dispatch, rootState }, uid) {
    const { backEndRepository } = rootState.dependencies
    await backEndRepository.generateOrderDataExport(uid)
    await dispatch('setOrders')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
