import Vue from 'vue'
import VueI18n from 'vue-i18n'

import zh_CN from './zh-CN.json'
import en_US from './en-US.json'
import en_countries from './en_countries'
import cn_countries from './cn_countries'

Vue.use(VueI18n)

const messages = {
  'zh-CN': { ...zh_CN, ...cn_countries },
  'en-US': { ...en_US, ...en_countries },
}

const i18n = new VueI18n({
  messages,
  silentTranslationWarn: true,
})
Vue.use({ i18n: (key, value) => i18n.t(key, value) })
export default i18n
