<template>
  <v-col sm="12">
    <v-data-table :headers="headers" :items="theUsers" :items-per-page="50" class="elevation-1" item-key="uid">
      <template v-slot:top>
        <v-text-field v-model="search" label="Search" class="mx-4" style="font-style: italic" />
      </template>

      <template v-slot:[`item.country`]="{ item }">
        {{ $t(item.country) }}
      </template>

      <template v-slot:[`item.exclusiveDistributorOf`]="{ item }">
        {{ item.exclusiveDistributorOf.length ? item.exclusiveDistributorOf.join(', ') : 'No brand' }}
      </template>

      <template v-slot:[`item.pipedriveId`]="{ item }">
        <a v-if="item.pipedriveId" target="_blank" :href="getPipeDrivePersonLink(item.pipedriveId)">{{
          item.pipedriveId
        }}</a>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <router-link :to="`/user/${item.uid}`">
          <v-icon small class="mr-2"> mdi-eye </v-icon>
        </router-link>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import { getPipeDrivePersonLink } from '../services/usersService'

export default {
  props: ['users'],

  data: () => ({
    headers: [
      { text: 'EMAIL', value: 'email' },
      { text: 'ALIAS', value: 'alias' },
      { text: 'FIRSTNAME', value: 'firstName' },
      { text: 'LASTNAME', value: 'lastName' },
      { text: 'COUNTRY', value: 'country' },
      { text: 'EXCLUSIVE DISTRIB.OF', value: 'exclusiveDistributorOf' },
      { text: 'BRANDWHITELIST', value: 'brandWhitelist' },
      { text: 'PIPEDRIVE ID', value: 'pipedriveId' },
      { text: 'ACTIONS', value: 'actions' },
    ],

    search: '',

    getPipeDrivePersonLink,
  }),

  computed: {
    theUsers() {
      return this.users.filter(({ email, alias, firstName, lastName, country }) => {
        if (!this.search) return true

        return (
          email?.toLowerCase().includes(this.search.toLowerCase()) ||
          alias?.toLowerCase().includes(this.search.toLowerCase()) ||
          firstName?.toLowerCase().includes(this.search.toLowerCase()) ||
          lastName?.toLowerCase().includes(this.search.toLowerCase()) ||
          country?.toLowerCase().includes(this.search.toLowerCase())
        )
      })
    },
  },
}
</script>
