<template>
  <v-col sm="12">
    <v-data-table :headers="headers" :items="items" :items-per-page="50" class="elevation-1" item-key="reference">
      <template v-slot:[`item.unitPrice`]="{ item }">
        {{ getFormattedPrice(item.unitPrice) }}
      </template>

      <template v-slot:[`item.totalPrice`]="{ item }">
        {{ getFormattedPrice(item.totalPrice) }}
      </template>

      <template v-slot:[`item.discount`]="{ item }">
        {{ getFormattedPrice(item.discount) }}
      </template>

      <template v-slot:[`item.discountedTotalPrice`]="{ item }">
        {{ getFormattedPrice(item.discountedTotalPrice) }}
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import { getFormattedPrice } from '../services/formatting'

export default {
  props: ['items'],

  data: () => ({
    headers: [
      { text: 'Reference', value: 'reference' },
      { text: 'Quantity', value: 'quantity', align: 'right' },
      { text: 'Unit Price', value: 'unitPrice', align: 'right' },
      { text: 'Total Price', value: 'totalPrice', align: 'right' },
      { text: 'Discount', value: 'discount', align: 'right' },
      { text: 'Total Price After Discount', value: 'discountedTotalPrice', align: 'right' },
    ],

    getFormattedPrice,
  }),
}
</script>
