const OrderStatuses = {
  draft: 'draft',
  discarded: 'discarded',
  confirmed: 'confirmed',
  processing: 'processing',
  readyToSend: 'readyToSend',
  inTransit: 'inTransit',
  closed: 'closed',
}

export default OrderStatuses
